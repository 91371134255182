import React from 'react';
import {Link} from "react-router-dom";

const MainFooter = ({logo = ""}) => {
    return (
        <div className="main--footer">
            <Link to="/" style={{textDecoration:"none"}}>
                <img className="logo" src={logo} alt="logo"/>
            </Link>
            <div className="title-footer">© 2023. All rights Reserved</div>
            <Link to="/" style={{textDecoration:"none"}}>
                <div className="title-footer">Condition/Mention Légal</div>
            </Link>
            <a href="https://www.linkedin.com/in/david-henri-arnaud/">
                <img className="img-social" src="/assets/logo/linkedin.svg" alt="linkedin logo"/>
            </a>
        </div>
    );
};

export default MainFooter;
import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {Alert} from "@mui/lab";
import axios from "axios";
import AuthService from "../Auth/AuthService";
import {Snackbar} from "@mui/material";

const Contact = ({refContact}) => {

    const { register, handleSubmit,resetField, watch } = useForm();
    const [message,setMessage] = useState("")
    const [openFailed,setOpenFailed] = useState(false)
    const [openCreated, setOpenCreated]  = useState(false)
    const [isLoader,setIsLoader] = useState(false)

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailed(false)
    };

    const resetForm = () => {
        resetField('username');
        resetField('company');
        resetField('email');
        resetField('tel');
        resetField('description');
    }

    const requestApi = data => {
        axios
            .post(`${AuthService.API_URL}/client-demand/create`, data)
            .then((res) => {
            if(res.status === 201) {
                setMessage("Nous avons bien reçu votre demande et nous vous recontacterons sous peu")
                setOpenCreated(true)
                setIsLoader(false)
                resetForm()
                setTimeout(()=> {
                    setOpenCreated(false)
                },3000)
            }else{
                setMessage("Nous n'avions pas reçu votre demande. Si le probleme persite contacter nous par téléphone")
                setOpenFailed(true)
                setIsLoader(false)
                setTimeout(()=> {
                    setOpenFailed(false)
                },3000)
            }
        })
            .catch((err) => {
                if(err){
                    setMessage("Nous n'avions pas reçu votre demande. Si le probleme persite contacter nous par téléphone")
                    setOpenFailed(true)
                    setIsLoader(false)
                    setTimeout(()=> {
                        setOpenFailed(false)
                    },3000)
                }
            });
    }


    const onSubmit = (data) => {
        if(watch('username') === "" || watch('company') === "" || watch('email') === "" || watch('tel') === ""|| watch('description') === ""  ){
            setOpenFailed(true)
            setMessage("Veuillez compléter les informations du formulaire")
            setTimeout(()=> {
                setOpenFailed(false)
            },3000)
        }else{
            setIsLoader(true)
            requestApi(data)
        }
    }





    return (
        <>
            <div ref={refContact} className="contact--component">
                <div className="title-first-component">Une idée en tête, N’hésitez pas à nous contacter</div>
                <form onSubmit={handleSubmit(onSubmit)} className="container-form">
                    <div className="container-form-left">
                        <div className="title-content" style={{marginTop:20}}>Email</div>
                        <div className="container-global-input">
                            <div className="container-input">
                                <input placeholder="Nom Prénom" name="username" {...register("username")}/>
                                <input placeholder="Entreprise" name="compagny" {...register("company")}/>
                            </div>
                            <div className="container-input">
                                <input placeholder="Email" name="email" {...register("email")}/>
                                <input placeholder="Tel" name="tel" {...register("tel")}/>
                            </div>
                            <textarea placeholder="Description" name="description" {...register("description")}/>
                            <div className="container-right">
                                <button>ENVOYER</button>
                            </div>
                        </div>
                    </div>
                    <img src="/assets/images/contact.png" alt=""/>
                </form>
                <div className="container-other-contact">
                    <div className="container-method-contact">
                        <div className="title-method-contact">Par Téléphone</div>
                        <div className="subtitle-method-contact">Au: 06.80.18.28.12</div>
                    </div>
                    <div className="container-method-contact position">
                    </div>
                </div>
            </div>
            {
                isLoader === true &&
                <div id="preloader">
                    <div id="loader"></div>
                </div>
            }
            <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Contact;
import React, {useEffect} from "react"
import Lottie from 'react-lottie';

import Contact from "../components/Home/Contact";
import ValueThink from "../components/Home/ValueThink";
import Skills from "../components/Home/Skills";
import Tech from "../components/Home/Tech";
import personLotties from "../lotties/person-script.json";
import Typewriter from 'typewriter-effect/dist/core';
const Home = ({header,valueRef,skills,tech,contact}) => {



    const writer = () => {
        var app = document.getElementById('tw1');

        var typewriter = new Typewriter(app, {
            delay: 75,
        });

        typewriter
            .pauseFor(250)
            .typeString('<strong>En utilisant le numérique, vous pouvez optimiser votre processus et simplifier vos démarches.</strong>')
            .pauseFor(1000)
            .start();
    }



    useEffect(()=>{
        writer()
    },[])


    const headerLotties = {
        loop: true,
        autoplay: true,
        animationData: personLotties,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return(
        <div ref={header} className='view--home'>
            <div className="header-section">
                <div className="header-background">
                    <div className="title-first">The Idea of choice</div>
                    <div id="tw1" className="subtitle"></div>
                    <div className="container-button">
                        <div className="button">Demarer <img src="/assets/logo/arrow_right.png" alt=""/></div>
                    </div>
                </div>
                <div className="lottie big">
                    <Lottie
                        options={headerLotties}
                        height={536}
                        width={636}
                    />
                </div>
                <div className="lottie tablet">
                    <Lottie
                        options={headerLotties}
                        height={350}
                        width={400}
                    />
                </div>
                <div className="lottie mobile">
                    <Lottie
                        options={headerLotties}
                        height={350}
                        width={400}
                    />
                </div>
            </div>
            <Skills refSkill={skills}/>
            <div className="title-text">Ils sont de plus en plus nombreux à nous faire confiance</div>
            <div className="container-enterprise" >
                <img src="/assets/images/enterprise-1.png" alt="enterprise"/>
                <img src="/assets/images/enterprise-2.png" alt="enterprise2"/>
                <img src="/assets/images/enterprise-3.png" alt="enterprise3"/>
                <img src="/assets/images/enterprise-4.png" alt="enterprise4"/>
            </div>
            <Tech refTech={tech}/>
            <ValueThink refValue={valueRef}/>
            <Contact refContact={contact}/>
        </div>
    )
}

export default Home;
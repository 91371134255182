import React from 'react';
import TechExplain from "./TechExplain";

const Tech = ({refTech}) => {

    const languages = ["PHP","Python","Java","JavaScript"]
    const frameworks = ["Laravel","Nodejs","React","Flutter","Spring Boot"]
    const cms = ["WordPress"]
    const services = ["UX/UI Design", "DevOps"]

    return (
        <div ref={refTech} className="component--tech">
            <div className="title-component">Technology</div>
            <div className="container-tech">
                <div className="container-left-tech">
                    <div className="title-container">Domaines d'expérience</div>
                    <div className="subtitle-container">Il ne suffit pas toujours de connaître la théorie pour créer un produit efficace et utilisable.</div>
                    <br/>
                    <div className="subtitle-container">Une expérience pratique précieuse vient avec le temps passé en développement.</div>
                    <br/>
                    <div className="subtitle-container">Chaque année, nous élargissons notre éventail de compétences afin de répondre aux exigences et aux tendances les plus pointues.</div>
                    <br/>
                </div>
                <div className="container-right-tech">
                    <TechExplain title={"Languages"} languages={languages} />
                    <TechExplain title={"Frameworks"} languages={frameworks} />
                    <TechExplain title={"CMS"} languages={cms} />
                    <TechExplain title={"Services"} languages={services} />
                </div>
            </div>
        </div>
    );
};

export default Tech;

import NotFound from "./components/error/NotFound";
import Home from "./pages/Home";
import {
    Routes,
    Route, BrowserRouter as Router
} from "react-router-dom";
import MainHeader from "./layout/Main-Header";
import MainFooter from "./layout/Main-footer";
import {useCallback, useEffect, useRef, useState} from "react";


const App = () => {

  const logoPath = "/assets/logo/logo4.png"

    const contact = useRef(null)
    const values = useRef(null)
    const skills = useRef(null)
    const tech = useRef(null)
    const homeHeader = useRef(null)

    const executeScroll = (myref) => {
        console.log(myref)
        myref.current.scrollIntoView()
    }



  return (
    <Router>
        <MainHeader logo={logoPath} headerHome={homeHeader} value={values} skills={skills} tech={tech} contact={contact} executionScroll={executeScroll} />
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route index element={<Home header={homeHeader} valueRef={values} skills={skills} tech={tech} contact={contact}   />} />
        </Routes>
        <MainFooter logo={logoPath}/>
    </Router>
  );


}

export default App;

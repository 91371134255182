import React from 'react';
import Lottie from "react-lottie";
import designLotties from "../../lotties/design.json";
import devLotties from "../../lotties/development.json";
import serverLotties from "../../lotties/server.json";
import phoneLotties from "../../lotties/phone.json";
import reseauLotties from "../../lotties/reseaux.json";

const Skills = ({refSkill}) => {


    const skillDesignLotties = {
        loop: true,
        autoplay: true,
        animationData: designLotties,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const skillDevLotties = {
        loop: true,
        autoplay: true,
        animationData: devLotties,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const skillServerLotties = {
        loop: true,
        autoplay: true,
        animationData: serverLotties,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const skillServiceLotties = {
        loop: true,
        autoplay: true,
        animationData: phoneLotties,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const skillExplainLotties = {
        loop: true,
        autoplay: true,
        animationData: reseauLotties,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <div ref={refSkill} className="component--skills">
                <div className="text-style-header">Si vous pouvez l’imaginez, nous pouvons le concevoir.</div>
                <div className="container-skill">
                    <div className="about-skill">
                        <div className="bar"></div>
                        <div className="container-text-skill">
                            <div className="title-skill">Conception UX/UI</div>
                            <div className="subtitle-skill">Concevoir des interfaces optimisées est au cœur des préoccupations de nos équipes de développeurs et de designers.</div>
                            <div className="subtitle-skill">Notre démarche est de susciter l'adhésion de vos utilisateurs et de vos clients. Indispensable pour générer du résultat !</div>
                        </div>
                    </div>
                    <div className="lotties-skill big">
                        <Lottie
                            options={skillDesignLotties}
                            height={536}
                            width={636}
                        />
                    </div>
                    <div className="lotties-skill tablet">
                        <Lottie
                            options={skillDesignLotties}
                            height={400}
                            width={400}
                        />
                    </div>
                    <div className="lotties-skill mobile">
                        <Lottie
                            options={skillDesignLotties}
                            height={250}
                            width={250}
                        />
                    </div>
                </div>
                <div className="container-skill reverse">
                    <div className="lotties-skill big">
                        <Lottie
                            options={skillDevLotties}
                            height={536}
                            width={636}
                        />
                    </div>
                    <div className="lotties-skill tablet">
                        <Lottie
                            options={skillDevLotties}
                            height={400}
                            width={400}
                        />
                    </div>
                    <div className="lotties-skill mobile">
                        <Lottie
                            options={skillDevLotties}
                            height={250}
                            width={250}
                        />
                    </div>
                    <div className="about-skill">
                        <div className="bar"></div>
                        <div className="container-text-skill">
                            <div className="title-skill">Developpements</div>
                            <div className="subtitle-skill">Dynamisez votre activité grâce à des technologies innovantes  & Gagnez en productivité via des applications web, mobile ou logiciel conçues pour votre entreprise</div>
                        </div>
                    </div>
                </div>
                <div className="container-skill">
                    <div className="about-skill">
                        <div className="bar"></div>
                        <div className="container-text-skill">
                            <div className="title-skill">Déploiement</div>
                            <div className="subtitle-skill">N’ayer pas a vous soucier de deployé votre solution on s’occupe de tout ,de la sécurisation, à la mise en place de la solution dockerisé sur serveur</div>
                        </div>
                    </div>
                    <div className="lotties-skill big">
                        <Lottie
                            options={skillServerLotties}
                            height={536}
                            width={636}
                        />
                    </div>
                    <div className="lotties-skill tablet">
                        <Lottie
                            options={skillServerLotties}
                            height={400}
                            width={400}
                        />
                    </div>
                    <div className="lotties-skill mobile">
                        <Lottie
                            options={skillServerLotties}
                            height={250}
                            width={250}
                        />
                    </div>
                </div>
                <div className="container-skill reverse">
                    <div className="lotties-skill big">
                        <Lottie
                            options={skillServiceLotties}
                            height={536}
                            width={636}
                        />
                    </div>
                    <div className="lotties-skill tablet">
                        <Lottie
                            options={skillServiceLotties}
                            height={400}
                            width={400}
                        />
                    </div>
                    <div className="lotties-skill mobile">
                        <Lottie
                            options={skillServiceLotties}
                            height={250}
                            width={250}
                        />
                    </div>
                    <div className="about-skill">
                        <div className="bar"></div>
                        <div className="container-text-skill">
                            <div className="title-skill">Maintenance</div>
                            <div className="subtitle-skill">Nous chargons pour vous de maintenir vos soluton metier dans le temps en étant toujours a la point de la technologies pour assurer la pérénité de vos solutions </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-explain reverse">
                <div className="lotties-skill big">
                    <Lottie
                        options={skillExplainLotties}
                        height={836}
                        width={836}
                    />
                </div>
                <div className="lotties-skill tablet">
                    <Lottie
                        options={skillExplainLotties}
                        height={538}
                        width={400}
                    />
                </div>
                <div className="lotties-skill mobile">
                    <Lottie
                        options={skillExplainLotties}
                        height={250}
                        width={250}
                    />
                </div>
                <div className="container-text-explain">
                    <div className="title-explain">Accédez aux infrastructures du monde entier</div>
                    <div className="subtitle-explain">Que vous soyez un débutant absolu ou un client expérimenté, nous avons tous les services dont vous avez besoin pour gérer votre produit en toute simplicité.</div>
                </div>
            </div>
        </>
    );
};

export default Skills;
import React from 'react';

const TechExplain = ({title = "", languages = []}) => {
    return (
        <div className="component--tech-explain">
            <div className="title-tech-explain">{title}</div>
            {
                languages.map((value,index) =>
                    <div key={index} className="container-skill-tech">
                        <div className="title-skills-tech">{value}</div>
                        <div className="bar"/>
                    </div>
                )
            }
        </div>
    );
};

export default TechExplain;
import React from 'react';

const ValueThink = ({refValue}) => {
    return (
        <div ref={refValue} className="component--value-think">
            <div className="container-text-value-explain">
                <div className="title-explain-value">WeWorkStudio is a sensible solution studio</div>
                <div className="container-subtitle-value-explain">
                    <div className="subtitle-container">
                        <div className="title-subtitle">Souveraineté des données client</div>
                        <div className="subtitle-text">La dépendance est l'ennemie de la résilience. Vous souhaitez que vos données soient hébergées par un fournisseur régional et avoir un accés complet a vos données. Grace au accés au base de données et la possibilité d’extraction de vos donnée tous cela est possible.</div>
                    </div>
                    <div className="vide"></div>
                </div>
                <div className="container-subtitle-value-explain">
                    <div className="vide"></div>
                    <div className="subtitle-container">
                        <div className="title-subtitle">Les datacenters neutres en carbone</div>
                        <div className="subtitle-text">Nous nous engageons dans l’environement grace au services partenaire qui s’engage a etre neutre en carbon qui recycle leurs équipements physiquest. De plus, leur indicateur d'efficacité énergétique (PUE) est disponible en ligne et en temps réel.</div>
                    </div>
                </div>
                <div className="container-subtitle-value-explain">
                    <div className="subtitle-container">
                        <div className="title-subtitle">Une latence faible</div>
                        <div className="subtitle-text">La demande grandissante en terme d’amelioration & d’optimisation ,on s’engage a vous proposer un service de qualité autant en terme de UX/UI qu’en optimisant l’architecture de votre produit pour qu’il soit en toute circonstance agréable pour vous ou vos clients.</div>
                    </div>
                    <div className="vide"></div>
                </div>
            </div>
            <img src="/assets/images/terre.png" alt="terre"/>
        </div>
    );
};

export default ValueThink;
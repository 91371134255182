import {useCallback, useEffect, useState} from "react";


const MainHeader = ({logo = "",headerHome,value,skills,tech,contact,executionScroll}) => {

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            let element = document.getElementById("myDIV");
            if (y < window.scrollY) {
                console.log("scrolling up");
                element.classList.add("white-main-header");
            } else {
                element.classList.remove("white-main-header");
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);



    return (
        <div id="myDIV" className="main-header">
            <img src={logo} alt="logo weworkstudio" className="logo" style={{cursor:"pointer"}} onClick={() => executionScroll(headerHome)}/>
            <div className="link-container">
                <div className="link responsive" onClick={() => executionScroll(headerHome)}>Accueil</div>
                <div className="link responsive" onClick={() => executionScroll(skills)}>Nos Expertises</div>
                <div className="link responsive" onClick={() => executionScroll(tech)}>Stack Technologique</div>
                <div className="link exp responsive" onClick={() => executionScroll(value)}>Nos Valeurs</div>
                <div className="button" onClick={() => executionScroll(contact)}>Démarer</div>
            </div>
        </div>
    );
};

export default MainHeader;